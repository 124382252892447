<template>
  <v-app>
    <header>
      <nav class="navbar">
        <img src="./assets/logo.png" height="100"/>
      </nav>
    </header>
    <v-main>
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './views/Home';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
.navbar {
  height: 3.5rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
  transition: height 0.25s;
  background: #08697e;
}
</style>