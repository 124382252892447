<template>
    <v-row class="text-center">
        <v-overlay :value="overlay">
            <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
        </v-overlay>
        <v-col class="mt-6 mb-4">
            <h1 class="display-2 font-weight-normal mb-3">
                Aplicación Modelo
            </h1>
            <h6 class="display-1 font-weight-light mb-3">
                clasificación de riesgo ambiental
            </h6>
            <v-card class="mx-auto mb-2" max-width="500" outlined elevation="2" v-if="!mostrarGrafico">
                <v-row class="mt-2 ml-2 mr-2">
                    <v-file-input
                        v-model="file"
                        accept=".xlsx"
                        label="Suba un archivo xlsx"
                        :success-messages="messages"
                        :error-messages="errorMessages"
                    ></v-file-input>
                    <a href="#" style="align-content: center;" class="ml-2" @click="downloadFile">Formato ejemplo</a>
                </v-row>
                <v-row class="ml-2 mr-2">
                    <v-col>
                        <v-btn
                        :loading="loadingCargar"
                        :disabled="loadingCargar"
                        color="blue-grey"
                        class="ma-2 white--text"
                        @click="cargarXlsx"
                        >
                        Cargar archivo
                            <v-icon 
                                right
                                dark
                            >
                                mdi-cloud-upload
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-btn
                            class="ma-2"
                            :loading="loadingProcesar"
                            :disabled="loadingProcesar"
                            color="success"
                            @click="procesar"
                            v-if="excelData"
                            >
                            Procesar
                            <template v-slot:loader>
                                <span>Procesando...</span>
                            </template>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="mx-auto mb-2" max-width="600" outlined elevation="2" v-if="mostrarGrafico">
                <v-row style="justify-content: center;">
                    <h3 class="font-weight-light mb-3 mt-3">Gráfico de Prioridad</h3>
                </v-row>
                <v-row class="ml-2 mr-2 mt-2">
                    <Bar
                        id="chartBarPrioridad"
                        :options="chartOptions"
                        :data="chartData"
                    />
                </v-row>
                <v-row class="ml-2 mb-2">
                    <v-btn
                        class="ma-2"
                        color="success"
                        @click="refresh()"
                        >
                        Procesar otro documento
                    </v-btn>
                </v-row>
            </v-card>
            <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Contenido cargado
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card class="mx-auto mt-2 ml-4 mr-4" outlined elevation="2" v-if="excelData">
                            <v-data-table
                                :headers="headers"
                                :items="excelData"
                                :items-per-page="5"
                                class="elevation-1 my-table"
                                :footer-props="{
                                    'items-per-page-text':'Registros por página',
                                    'items-per-page-all-text': 'Todos',
                                }"
                            >
                                <template v-slot:[`item.TranscripcionLiteral`]="{ item }">
                                    <div
                                        v-html="item.TranscripcionLiteral"
                                        class="scrollable-div-data"
                                    ></div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Resultados
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card class="mx-auto mt-2 ml-4 mr-4" outlined elevation="2" v-if="responseData">
                            <v-row class="ml-2 mr-2 mt-2 mb-2">
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="ma-2"
                                    :loading="loadingExportar"
                                    :disabled="loadingExportar"
                                    color="success"
                                    @click="exportToExcel"
                                    v-if="responseData"
                                    >
                                    Exportar
                                    <template v-slot:loader>
                                        <span>Exportando...</span>
                                    </template>
                                </v-btn>
                            </v-row>
                            <v-row class="ml-0 mr-0">
                                <v-data-table
                                    :headers="headersResponse"
                                    :items="responseData"
                                    :items-per-page="5"
                                    class="elevation-1 my-table"
                                    :footer-props="{
                                        'items-per-page-text':'Registros por página',
                                        'items-per-page-all-text': 'Todos',
                                    }"
                                >
                                    <template v-slot:[`item.transcripcionLiteral`]="{ item }">
                                        <div
                                            v-html="item.transcripcionLiteral"
                                            class="scrollable-div-response"
                                        ></div>
                                    </template>
                                </v-data-table>
                            </v-row>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <!--<v-expansion-panel v-if="mostrarGrafico">
                    <v-expansion-panel-header>
                        Gráfico
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card class="mx-auto mb-2" max-width="500" outlined elevation="2">
                            <Bar
                                id="chartBarPrioridad"
                                :options="chartOptions"
                                :data="chartData"
                            />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>-->
            </v-expansion-panels>
        </v-col>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Error de validación
                </v-card-title>
                <v-card-text>
                    <v-textarea
                        readonly
                        solo
                        name="input-7-4"
                        auto-grow
                        v-html="getMensajeValidador()"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    Aceptar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import * as XLSX from "xlsx";
    import axios from "axios";
    import { Bar } from 'vue-chartjs'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'HomeModelo',
        components: { Bar },
        data () {
            return {
                loader: null,
                loadingProcesar: false,
                loadingCargar: false,
                file: null,
                excelData: null,
                headers: [
                    { text: 'Nro', value: 'Nro', width: '70px' },
                    { text: 'Transcripción Literal', value: 'TranscripcionLiteral', width: '500px' },
                    { text: 'Inversión', value: 'InversionSEIA', width: '100px' },
                    { text: 'Región', value: 'Region', width: '100px' },
                    { text: 'Cat Proyecto', value: 'CategoriaDelProyecto', width: '120px' },
                    { text: 'Tipo Instrumento', value: 'TipoDeInstrumento', width: '150px' },
                    { text: 'Componente', value: 'Componente', width: '120px' },
                    { text: 'Subcomponente', value: 'Subcomponente', width: '140px' },
                    { text: 'Acción Medida', value: 'AccionMedida', width: '140px' },
                    { text: 'Cualidad Medida', value: 'CualidadMedida', width: '150px' },
                    { text: 'Elusion Previa', value: 'ElusionPrevia', width: '130px' },
                    { text: 'Ocultamiento Previo', value: 'OcultamientoPrevio', width: '160px' },
                    { text: 'Reiteración Leve', value: 'ReiteracionLeve', width: '140px' },
                    { text: 'Reiteración Grave', value: 'ReiteracionGrave', width: '150px' },
                    { text: 'Afectación Negativa', value: 'AfectacionNegativa', width: '160px' },
                    { text: 'Impacto Significativo', value: 'ImpactoSignificativo', width: '165px' },
                    { text: 'Área Protegida', value: 'AreaProtegida', width: '140px' },
                    { text: 'Prioridad Interna', value: 'PrioridadInterna', width: '150px' }
                ],
                headersResponse: [
                    { text: 'Nro', value: 'nro', width: '70px' },
                    { text: 'Transcripción Literal', value: 'transcripcionLiteral', width: '838px' },
                    { text: 'Componente', value: 'componente', width: '120px' },
                    { text: 'Sub-componente', value: 'subcomponente', width: '150px' },
                    { text: 'Acción Medida', value: 'accionMedida', width: '140px' },
                    { text: 'Cualidad Medida', value: 'cualidadMedida', width: '150px' },
                    { text: 'Probabilidad Grave %', value: 'probGrave', width: '180px' },
                    { text: 'Prioridad', value: 'prioridad', width: '100px' }
                ],
                datosProcesar: null,
                responseData: null,
                panels: [],
                overlay: false,
                loadingExportar: false,
                messages: [],
                chartData: {
                    labels: [],
                    datasets: []
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                        display: false,
                        },
                    }
                },
                mostrarGrafico: false,
                errorMessages: [],
                Region: [
                    'region de antofagasta',
                    'region del biobio',
                    'region de atacama',
                    'region del maule',
                    'region de coquimbo',
                    'region del libertador general bernardo ohiggins',
                    'region metropolitana de santiago',
                    'region de valparaiso',
                    'region de los rios',
                    'region de tarapaca',
                    'region de la araucania',
                    'region de los lagos',
                    'region de arica',
                    'region de aysen',
                    'region de magallanes',
                    'region de ñuble',
                    'interregional'
                ],
                CategoriaDelProyecto: [
                    'Agroindustrias',
                    'ETFAS',
                    'Energia',
                    'Equipamiento',
                    'Forestal',
                    'Infraestructura Hidraulica',
                    'Infraestructura Portuaria',
                    'Infraestructura Transporte',
                    'Instalacion Fabril',
                    'Mineria',
                    'Otras Categorias',
                    'Pesca y Acuicultura',
                    'Saneamiento Ambiental',
                    'Transportes y Almacenajes',
                    'Vivienda e Inmobiliarios'
                ],
                TipoDeInstrumento: [
                    'Elusion',
                    'Fraccionamiento',
                    'MUTP',
                    'NE',
                    'PPDA',
                    'RCA_DIA',
                    'RCA_EIA',
                    'RETC',
                    'RSMA_ICG',
                    'ReqInfo'
                ],
                Componente: [
                    'Medio Humano',
                    'Residuos',
                    'Paisajismo',
                    'Medio Ambiente',
                    'Suelo',
                    'Aire',
                    'Patrimonio',
                    'Agua',
                    'Biodiversidad'
                ],
                Subcomponente: [
                    'Sustancias Quimicas y Combustibles',
                    'Aguas Captacion',
                    'Agua - Otros',
                    'Edafologia y Calidad de Suelos',
                    'Medio Humano - Otros',
                    'Ruido',
                    'Residuos Solidos',
                    'Seguridad y Prevencion de Riesgos',
                    'Aguas Superficiales',
                    'Luminica',
                    'Biodiversidad - Otros',
                    'Vialidad',
                    'Fauna Terrestre',
                    'Olores',
                    'Vibraciones',
                    'Caracteristicas y/o Equipamiento del Proyecto o Actividad',
                    'Vectores',
                    'Avifauna',
                    'Agua Potable o Consumo',
                    'Ictofauna',
                    'Flora y Vegetacion Terrestre',
                    'Calidad del Aire',
                    'Residuos Liquidos',
                    'Paisaje',
                    'Patrimonio Historico y Cultural',
                    'Residuos - Otros',
                    'Campos Electromagneticos y/o Radiaciones',
                    'Residuos Peligrosos',
                    'Aguas Subterraneas',
                    'Medidas Generales'
                ],
                AccionMedida: [
                    'Reportar',
                    'Afectar',
                    'Exceder',
                    'Medir',
                    'Compensar',
                    'Mitigar',
                    'Ejecutar',
                    'Acreditar',
                    'Implementar',
                    'Contar',
                    'Reparar',
                    'Acatar'
                ],
                CualidadMedida: [
                    'Peligroso',
                    'Sensible',
                    'Superior',
                    'Deficiente',
                    'Industrial',
                    'Cercano',
                    'Frecuente',
                    'Exigente',
                    'Adicional',
                    'Significativo',
                    'Mortal',
                    'Intermitente'
                ],
                PrioridadInterna: [
                    'Muy Baja',
                    'Baja',
                    'Media',
                    'Alta',
                    'Muy Alta'
                ],
                mensajesValidador: [],
                dialog: false,
                columnasValidar: ['Region','CategoriaDelProyecto','TipoDeInstrumento','Componente','Subcomponente','AccionMedida','CualidadMedida','PrioridadInterna']
            }
        },
        methods: {
            cargarXlsx() {
                if (this.file) {
                    this.loadingCargar = true
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const data = new Uint8Array(e.target.result);
                        const workbook = XLSX.read(data, { type: "array" });
                        // Asumimos que queremos leer la primera hoja
                        const firstSheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[firstSheetName];
                        const range = XLSX.utils.decode_range(worksheet['!ref']);
                        // Validar formato excel
                        // Validar encabezado
                        if (!this.validarEncabezado(XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0])) {
                            this.errorMessages = 'Formato no valido [Encabezado incorrecto]'
                            this.messages = []
                            this.loadingCargar = false;
                            this.excelData = null
                            return;
                        }
                        // Validar datos
                        this.mensajesValidador = []
                        this.validarDatos(worksheet)
                        
                        if (this.mensajesValidador.length > 0) {
                            this.errorMessages = 'Formato no valido'
                            this.messages = []
                            this.loadingCargar = false;
                            this.excelData = null
                            return;
                        }
                        let arr = [...Array(range.e.r).keys()]
                        const columnaNro = ['Nro']
                        for (let index = 1; index < arr.length + 1; index++) {
                            columnaNro.push(index)
                        }
                        for (let R = range.s.r; R <= range.e.r; R++) {
                            for (let C = range.e.c; C >= range.s.c; C--) {
                                const sourceCell = XLSX.utils.encode_cell({ c: C, r: R });
                                const targetCell = XLSX.utils.encode_cell({ c: C + 1, r: R });
                                worksheet[targetCell] = worksheet[sourceCell];
                                delete worksheet[sourceCell];
                            }
                        }
                        columnaNro.forEach((dato, index) => {
                            const cellAddress = XLSX.utils.encode_cell({ c: 0, r: index });
                            worksheet[cellAddress] = { t:'s', v: dato };
                        });
                        range.e.c = range.e.c + 1;
                        worksheet['!ref'] = XLSX.utils.encode_range(range);

                        // Convertir hoja a JSON
                        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        const jsonData2 = XLSX.utils.sheet_to_json(worksheet, { header: 2 });
                        this.excelData = jsonData2;

                        let titulo = ""
                        let arreglo = []
                        let retorno = []
                        for (let index = 0; index < jsonData[0].length; index++) {
                            arreglo = []
                            titulo = ""
                            jsonData.forEach(element => {
                                if (titulo === "") {
                                    titulo = JSON.stringify(element[index])
                                } else {
                                    if (this.columnasValidar.includes(titulo.replaceAll('"',''))) {
                                        if (JSON.stringify(element[index])) {
                                            arreglo.push(JSON.stringify(element[index].toLowerCase().replaceAll('á','a').replaceAll('é','e').replaceAll('í','i').replaceAll('ó','o').replaceAll('ú','u').replaceAll('ñ','n')))
                                        } else {
                                            arreglo.push('null')
                                        }
                                    } else {
                                        if (JSON.stringify(element[index])) {
                                            arreglo.push(JSON.stringify(element[index]))
                                        } else {
                                            arreglo.push('null')
                                        }
                                    }
                                }
                            });
                            retorno += titulo + ': [' + arreglo + '],'
                        }
                        this.datosProcesar = '{' + retorno.substring(0,retorno.length-1) + '}'
                        this.loadingCargar = false;
                        this.messages = 'Documento cargado exitosamente'
                        this.errorMessages = []
                    };
                    reader.readAsArrayBuffer(this.file);
                }
            },
            async procesar() {
                this.loadingProcesar = true;
                this.overlay = true
                let prioridadesUnicas = []
                let prioridadesUnicasOrden = []
                await axios.post(process.env.VUE_APP_BACKEND_URL + '/predict', JSON.parse(this.datosProcesar)).then((response) => {
                    let prioridades = response.data.Prioridad
                    let probGrave = response.data.ProbGrave
                    let otros = response.data.X_inference
                    let datosResp = []

                    prioridadesUnicas = prioridades.filter(this.onlyUnique)
                    prioridadesUnicas.forEach(element => {
                        if (element == 'Muy Baja') {
                            prioridadesUnicasOrden[0] = element
                        } else if (element == 'Baja') {
                            prioridadesUnicasOrden[1] = element
                        } else if (element == 'Media') {
                            prioridadesUnicasOrden[2] = element
                        } else if (element == 'Alta') {
                            prioridadesUnicasOrden[3] = element
                        } else if (element == 'Muy Alta') {
                            prioridadesUnicasOrden[4] = element
                        }
                    });
                    let dataChart = []
                    let labelsChart = []
                    let obj = {
                        borderColor: '#36A2EB',
                        backgroundColor: '#9BD0F5',
                        data: []
                    }
                    prioridadesUnicasOrden.forEach(element => {
                        obj.data.push(prioridades.filter(e => e === element).length)
                        labelsChart.push(element)
                    });
                    dataChart.push(obj)
                    this.chartData.labels = labelsChart
                    this.chartData.datasets = dataChart

                    for (let index = 0; index < otros.TranscripcionLiteral.length; index++) {
                        const datos = {
                            nro: index + 1,
                            transcripcionLiteral: otros.TranscripcionLiteral[index],
                            componente: otros.Componente[index],
                            subcomponente: otros.Subcomponente[index],
                            accionMedida: otros.AccionMedida[index],
                            cualidadMedida: otros.CualidadMedida[index],
                            probGrave: (probGrave[index] * 100).toFixed(2),
                            prioridad: prioridades[index]
                        }
                        datosResp.push(datos)
                    }
                    this.responseData = datosResp
                    this.loadingProcesar = false;
                    this.panels = [1]
                    this.overlay = false
                    this.mostrarGrafico = true
                }).catch((error) => {
                    this.errorMessages = 'Error al procesar registros, contactar al administrador'
                    this.messages = []
                    this.loadingCargar = false;
                    this.excelData = null
                    this.overlay = false
                    console.error(error);
                    this.loadingProcesar = false;
                });
            },
            exportToExcel() {
                this.loadingExportar = true;
                // Crear una hoja de cálculo con los datos
                const ws = XLSX.utils.json_to_sheet(this.responseData);
                
                // Crear un libro de trabajo y agregar la hoja de cálculo
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Riesgo ambiental');

                // Exportar el libro de trabajo a un archivo Excel
                XLSX.writeFile(wb, 'riesgo ambiental.xlsx');
                this.loadingExportar = false;
            },
            onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            },
            refresh() {
                location.reload();
            },
            async downloadFile() {
                try {
                    const response = await axios({
                        url: process.env.VUE_APP_BACKEND_URL + '/download/example',  // Asegúrate de que la URL sea correcta
                        method: 'GET',
                        responseType: 'blob',  // Cambiar el tipo de respuesta a 'blob' para archivos binarios
                    });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'FormularioBasico.xlsx');  // Reemplaza con el nombre del archivo que quieres descargar
                    document.body.appendChild(link);
                    link.click();
                } catch (error) {
                    console.error('Error descargando el archivo', error);
                }
            },
            validarEncabezado(encabezado) {
                if (encabezado[0] != 'TranscripcionLiteral') {
                    return false
                } else if (encabezado[1] != 'InversionSEIA') {
                    return false
                } else if (encabezado[2] != 'Region') {
                    return false
                } else if (encabezado[3] != 'CategoriaDelProyecto') {
                    return false
                } else if (encabezado[4] != 'TipoDeInstrumento') {
                    return false
                } else if (encabezado[5] != 'Componente') {
                    return false
                } else if (encabezado[6] != 'Subcomponente') {
                    return false
                } else if (encabezado[7] != 'AccionMedida') {
                    return false
                } else if (encabezado[8] != 'CualidadMedida') {
                    return false
                } else if (encabezado[9] != 'ElusionPrevia') {
                    return false
                } else if (encabezado[10] != 'OcultamientoPrevio') {
                    return false
                } else if (encabezado[11] != 'ReiteracionLeve') {
                    return false
                } else if (encabezado[12] != 'ReiteracionGrave') {
                    return false
                } else if (encabezado[13] != 'AfectacionNegativa') {
                    return false
                } else if (encabezado[14] != 'ImpactoSignificativo') {
                    return false
                } else if (encabezado[15] != 'AreaProtegida') {
                    return false
                } else if (encabezado[16] != 'PrioridadInterna') {
                    return false
                }
                return true
            },
            validarDatos(worksheet) {
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                for (let colNum = 0; colNum <= range.e.c; colNum++) {
                    switch (colNum) {
                        case 1:
                            // Validar inversion
                            this.validarDecimal(worksheet, range)
                            break;
                        case 2:
                            // Validar Region
                            this.existeDato(worksheet, colNum, this.Region, range)
                            break;
                        case 3:
                            // Validar CategoriaDelProyecto
                            this.existeDato(worksheet, colNum, this.CategoriaDelProyecto, range)
                            break;
                        case 4:
                            // Validar TipoDeInstrumento
                            this.existeDato(worksheet, colNum, this.TipoDeInstrumento, range)
                            break;
                        case 5:
                            // Validar Componente
                            this.existeDato(worksheet, colNum, this.Componente, range)
                            break;
                        case 6:
                            // Validar Subcomponente
                            this.existeDato(worksheet, colNum, this.Subcomponente, range)
                            break;
                        case 7:
                            // Validar AccionMedida
                            this.existeDato(worksheet, colNum, this.AccionMedida, range)
                            break;
                        case 8:
                            // Validar CualidadMedida
                            this.existeDato(worksheet, colNum, this.CualidadMedida, range)
                            break;
                        case 9:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 10:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 11:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 12:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 13:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 14:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 15:
                            this.validarNumero(worksheet, colNum, range)
                            break;
                        case 16:
                            // Validar PrioridadInterna
                            this.existeDato(worksheet, colNum, this.PrioridadInterna, range)
                            break;
                        default:
                            break;
                    }
                }
                if (this.mensajesValidador.length > 0) {
                    this.dialog = true
                }
            },
            existeDato(worksheet, colNum, arreglo, range) {
                let lowerCaseArray = arreglo.map(element => element.toLowerCase().replaceAll('á','a').replaceAll('é','e').replaceAll('í','i').replaceAll('ó','o').replaceAll('ú','u').replaceAll('ñ','n'));
                const colName = { r: 0, c: colNum };
                const cellName = XLSX.utils.encode_cell(colName);
                const name = worksheet[cellName].v;
                for (let rowNum = 1; rowNum <= range.e.r; rowNum++) {
                    const cellAddress = { r: rowNum, c: colNum };
                    const cellRef = XLSX.utils.encode_cell(cellAddress);
                    const cell = worksheet[cellRef];
                    if (cell) {
                        if (!lowerCaseArray.includes((cell.v).toLowerCase().replaceAll('á','a').replaceAll('é','e').replaceAll('í','i').replaceAll('ó','o').replaceAll('ú','u').replaceAll('ñ','n'))) {
                            this.mensajesValidador.push('En fila ' + (rowNum + 1) + ', columna ' + name + ', el valor "' + cell.v + '" no es valido')
                        }
                    }
                }
            },
            getMensajeValidador() {
                let mensaje = ''
                this.mensajesValidador.forEach(element => {
                    mensaje += element + "</br>"
                });
                return mensaje
            },
            validarDecimal(worksheet, range) {
                const colName = { r: 0, c: 1 };
                const cellName = XLSX.utils.encode_cell(colName);
                const name = worksheet[cellName].v;
                for (let rowNum = 1; rowNum <= range.e.r; rowNum++) {
                    const cellAddress = { r: rowNum, c: 1 };
                    const cellRef = XLSX.utils.encode_cell(cellAddress);
                    const cell = worksheet[cellRef];
                    if (cell) {
                        if (!this.isFloat(cell.v) && !this.isInt(cell.v)) {
                            this.mensajesValidador.push('En fila ' + (rowNum + 1) + ', columna ' + name + ', el valor "' + cell.v + '" no es valido')
                        }
                    }
                }
            },
            isFloat(n) {
                return Number(n) === n && n % 1 !== 0;
            },
            isInt(n) {
                return Number(n) === n && n % 1 === 0;
            },
            validarNumero(worksheet, colNum, range) {
                const colName = { r: 0, c: colNum };
                const cellName = XLSX.utils.encode_cell(colName);
                const name = worksheet[cellName].v;
                for (let rowNum = 1; rowNum <= range.e.r; rowNum++) {
                    const cellAddress = { r: rowNum, c: colNum };
                    const cellRef = XLSX.utils.encode_cell(cellAddress);
                    const cell = worksheet[cellRef];
                    if (cell) {
                        if (cell.v !== 0 && cell.v !== 1) {
                            this.mensajesValidador.push('En fila ' + (rowNum + 1) + ', columna ' + name + ', el valor "' + cell.v + '" no es valido')
                        }
                    }
                }
            }
        },
        watch: {
            loader () {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },
    }
</script>
<style scoped>
.scrollable-div-data {
    max-height: 80px;
    height: 80px;
    overflow-y: auto; /* Esto permite el scroll vertical */
    max-width: 500px;
}
.scrollable-div-response {
    max-height: 80px;
    height: 80px;
    overflow-y: auto; /* Esto permite el scroll vertical */
    max-width: 838px;
}
::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    background-color: #08697e; /* Cambia esto al color que desees */
    color: white; /* Cambia esto al color de texto que desees */
}
::v-deep .active {
    background-color: #08697e !important; /* Cambia esto al color que desees */
    color: white !important; /* Cambia esto al color de texto que desees */
}
::v-deep .my-table tbody tr:nth-child(even) {
    background-color: #d7ebfc; /* Color para filas pares */
}
::v-deep .my-table tbody tr:nth-child(odd) {
    background-color: #ffffff; /* Color para filas impares */
}
</style>